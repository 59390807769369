import { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import SVG from "react-inlinesvg";

import styled from 'styled-components'
import IconClose from '../img/icons/icon_close.svg'
import {device} from "../media"
import ErrorBox from "../components/ErrorBox"
import i18next from 'i18next';

const LoginModal = ({active, close, subscriptions, login, user}) => {
    const {t} = useTranslation()
    const themeContext = useContext(ThemeContext);
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [showPasswordReset, setShowPasswordReset] = useState(false)
    const [showPasswordError, setShowPasswordError] = useState(false)

    const renderLinks = (item) => {
        //if there is a link in the current language, display that info
        if(item[i18next.language]){
            return <Link key={item[i18next.language].id} target="_blank" href={item[i18next.language].url}>{item[i18next.language].text}</Link>
        } else {
            return <Link key={item.id} target="_blank" href={item.url}>{item.text}</Link>
        }
    }

    return (
        <>
            {
                active &&
                <Modal>
                <Wrapper>
                    {
                    showPasswordReset ? 
                        <>
                            <Close src={IconClose} onClick={() => setShowPasswordReset(false)}/>
                            <Title>{t('reset_password')}</Title>
                            <InputLabel>{t('name')}</InputLabel>
                            <Input placeholder={t("username_placeholder")} value={username} onChange={(e) => {setUsername(e.target.value); setShowPasswordError(false)}}/>
                            <InputLabel>{t('email_adress')}</InputLabel>
                            <Input placeholder={t('email_adress')} value={email} onChange={(e) => {setEmail(e.target.value); setShowPasswordError(false)}}/>
                            {
                                showPasswordError &&
                                <ErrorBox text="Bitte füllen Sie mindestens eins der Felder aus."/>
                            }
                            <PasswordReset onClick={email || username ? () => setShowPasswordReset(false) : () => setShowPasswordError(true)} href={email || username ? `mailto:${themeContext.login[i18next.language]?.resetEmail ? themeContext.login[i18next.language].resetEmail: themeContext.login.resetEmail}?subject=${encodeURIComponent("Passwort zurücksetzen") || ''}&body=${encodeURIComponent(`Hallo,\n bitte setzen Sie mein Passwort zurück. \n\n Nutzername: ${username} \n Emailadresse: ${email}`) || ''}` : "#"}>{t("send")}</PasswordReset>
                            
                        </>
                                : 
                        <>
                            <Close src={IconClose} onClick={close}/>
                            <Title>{t('login_title')}</Title>
                            <Subtitle>{t('login_subtitle')}</Subtitle>
                            <InputLabel>{t('name')}</InputLabel>
                            <Input placeholder={t("username_placeholder")} value={username} onChange={(e) => setUsername(e.target.value)}/>
                            <InputLabel>{t('password')}</InputLabel>
                            <Input type="password" placeholder={t('login_password_placeholder')} value={password} onChange={(e) => setPassword(e.target.value)}/>
                            {
                                themeContext.login.resetEmail &&
                                <Link onClick={() => setShowPasswordReset(true)}>{t('reset_password')}</Link>
                            }
                            
                            {
                                user.error &&
                                <ErrorBox text="Leider konnten wir Sie nicht einloggen. Bitte übeprüfen Sie Ihre Daten und versuchen Sie es erneut."/>
                            }
                            
                            <LoginButton onClick={() => login(username, password)}>{t('log_in')}</LoginButton>
                            {
                                themeContext.login.subscriptionText &&
                                <>
                                {
                                    themeContext.login.links &&
                                    <Seperator>
                                        <SeperatorText>
                                        {t('or')}
                                        </SeperatorText> 
                                    </Seperator>
                                }
                                   
                                    <Title>{themeContext.login[i18next.language]?.subscriptionTitle ? themeContext.login[i18next.language].subscriptionTitle : themeContext.login.subscriptionTitle}</Title>
                                    <Subtitle dangerouslySetInnerHTML={{__html: themeContext.login[i18next.language]?.subscriptionText ? themeContext.login[i18next.language].subscriptionText : themeContext.login.subscriptionText}}></Subtitle>
                                    {
                                        themeContext.login.links && themeContext.login.links.map((link) => (
                                            renderLinks(link)
                                        ))
                                    }
                                </>
                            }
                        </>
                    }
                </Wrapper>

            </Modal>
            }
        </>

    )
}

export default LoginModal

const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(15, 23, 42, 0.24);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;

`

const Close = styled(SVG)`
    position: absolute;
    top: 28px;
    right: 28px;
    height: 12px;
    width: 12px;
    cursor: pointer;
    & path {
        fill: ${props => props.theme.color.grey_500};
    }
`

const Wrapper = styled.div`
    max-width: 400px; 
    border: 1px solid #E2E8F0;
    /* Shadow / XL */

    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 80%;
    overflow-y: auto;

    @media ${device.mobile}{
        margin: 24px 0px 50px;
    }
    
`

const Title = styled.span`
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 125%;
    color: ${props => props.theme.color.grey_900};
    margin-top: 32px;
`

const Subtitle = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: ${props => props.theme.color.grey_500};
    margin-top: 8px;

    a {
        color: ${props => props.theme.color.primary};
    }
`

const InputLabel = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: ${props => props.theme.color.grey_700};
    margin-top: 24px;
`

const Input = styled.input`
    height: 38px;
    margin-top: 6px;
    border: 1px solid ${props => props.theme.color.grey_200};
    box-sizing: border-box;
    /* Shadow/XS */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    padding: 12px;

    ::placeholder,
    ::-webkit-input-placeholder {
        color: ${props => props.theme.color.grey_400};
    }
    :-ms-input-placeholder {
        color: ${props => props.theme.color.grey_400};
    }

    &:focus {
        border: 1px solid ${props => props.theme.color.primary};
        outline: none;
    }
    
    @media ${device.mobile}{
        font-size: 16px;
    }
`

const LoginButton = styled.button`
    min-height: 38px;
    margin-top: 24px;
    width: 100%;
    background-color: ${props => props.theme.color.primary};
    /* Shadow/XS */
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border: none;
    color: white;
    cursor: pointer;

    &:hover{
        background-color: ${props => props.theme.color.primaryHover};
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    }
`

const Seperator = styled.span`
   width: 100%; 
   text-align: center; 
   border-bottom: 1px solid ${props => props.theme.color.grey_300}; 
   line-height: 0.1em;
   margin: 32px 0 0 0; 
`

const SeperatorText = styled.span`
    background:#fff; 
    padding: 0 10px; 
    color: ${props => props.theme.color.grey_500};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    
`

const Link = styled.a`
    color: ${props => props.theme.color.primary};
    margin-top: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    &:hover {
        cursor: pointer;
    }
`

const PasswordReset = styled.a`
    min-height: 38px;
    margin-top: 24px;
    width: 100%;
    background-color: ${props => props.theme.color.primary};
    /* Shadow/XS */
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover{
        background-color: ${props => props.theme.color.primaryHover};
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    }
`