import logo from './logo.jpg'
import editionIcon from '../../../img/icons/icon_book.svg'
import courseIcon from '../../../img/icons/icon_academy.svg'
import audioIcon from '../../../img/icons/icon_audio.svg'
import videoIcon from '../../../img/icons/icon_video.svg'
import banner from './banner.jpg'
import newsImage from './banner2.jpg'

export const theme = {
  color: {
    primary: "#44C799",
    primaryHover: "#00956b",
  },
  logoImage: logo,
  backend: "https://kitalino.publishing.one/",
  header: {
    customer: "KITALINO",
    title: "Fachimpulse",
    lineHeader: true,
  },
  sections: [
    {
      id: "1",
      label: "Bibliothek",
      label_single: "Bibliothek",
      type: "EDITION",
      icon: editionIcon,
      root: "edi"
    },
    {
      id: "2",
      label: "Akademie",
      label_single: "Akademie",
      type: "COURSE",
      icon: courseIcon,
      root: "ip"
    },
    {
      id: "3",
      label: "Videothek",
      label_single: "Videothek",
      type: "VIDEO",
      icon: videoIcon,
      root: "vid"
    },
    {
      id: "4",
      label: "Audiothek",
      label_single: "Audiothek",
      type: "AUDIO",
      icon: audioIcon,
      root: "aud"
    }
  ],
  tracking: {
    type: 'Matomo',
    urlBase: 'https://kitalino.matomo.cloud',
    siteId: 3,
  },
  newsSlider: {
    image: newsImage,
    link: "https://kitalino.com/fachimpulse/"
  },
  info: [
    {
      text: "Kitalino.com",
      link: 'https://kitalino.com/'
    },
    {
      text: 'Impressum',
      link: 'https://kitalino.com/impressum/'
    },
    {
      text: 'Datenschutz',
      link: 'https://kitalino.com/datenschutz/'
    },],
  login: {
    type: "internal",
    subscriptionTitle: "Registrierung",
    subscriptionText: "Teste sämtliche KITALINO Funktionen inklusive dem \"Fachimpulse GRATIS-PAKET\" für 4 Wochen kostenlos und unverbindlich.",
    links: [{
      id: 1,
      text: 'Hier registrieren',
      url: "https://einrichtungen.kitalino.com/#/registrieren"
    }
    ]
  }
};