import { useState, useContext, useEffect, useRef } from 'react';
import { ThemeContext } from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import {connect} from 'react-redux'
import styled from 'styled-components'
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton, PinterestShareButton, WhatsappShareButton} from 'react-share'
import {Link} from 'react-router-dom'
import {device} from "../media"
import HeaderButton from '../components/HeaderButton'
import Dropdown from '../components/Dropdown';
import IconShare from "../img/icons/icon_share.svg"
import IconDownload from "../img/icons/icon_download.svg"
import IconPrint from "../img/icons/icon_print.svg"
import IconCart from "../img/icons/icon_cart.svg"
import IconPDF from "../img/icons/icon_pdf.svg"
import DropdownButton from '../components/DropdownButton';
import DropdownItem from '../components/DropdownItem';
import SVG from "react-inlinesvg";
import IconClose from '../img/icons/icon_close.svg'
import IconFacebook from '../img/icons/icon_facebook.svg'
import IconFacebookMessenger from '../img/icons/icon_facebook_messenger.svg'
import IconTwitter from '../img/icons/icon_twitter.svg'
import IconX from '../img/icons/icon_x.svg'
import IconPinterest from '../img/icons/icon_pinterest.svg'
import IconLinkedin from '../img/icons/icon_linkedin.svg'
import IconXing from '../img/icons/icon_xing.svg'
import IconWhatsApp from '../img/icons/icon_whatsapp.svg'
import IconEmail from '../img/icons/icon_email.svg'
import IconLink from '../img/icons/icon_link.svg'
import IconCheckEmpty from '../img/icons/icon_check_empty.svg'
import IconCheck from '../img/icons/icon_check.svg'
import IconChevronRight from '../img/icons/icon_chevron_right.svg'
import ShareButton from 'react-share/lib/ShareButton';

type Props = {
    currentEdition: string,
    currentEditionTitle: string
    currentEditionShareURL: string
    currentEditionType: string
    currentEditionLesemodus: boolean
    editionId: string
    match: any
}

const ReaderHeader = ({currentEdition, currentEditionTitle, currentEditionShareURL, currentEditionType, currentEditionLesemodus, editionId, match}: Props) => {
    const themeContext = useContext(ThemeContext);
    const [dropdownActive, setDropdownActive] = useState(false)
    const [activeReaderButtons, setActiveReaderButtons] = useState({download: "false", print: "false", social: "false", cart: false})
    const [pageNumbers, setPageNumbers] = useState([])
    const [pageIds, setPageIds] = useState([])
    const [modalType, setModalType] = useState('')
    const [chosenPage, setChosenPage] = useState('')
    const [individualSharing, setIndividualSharing] = useState(false)
    const [sharingPlatforms, setSharingPlatforms] = useState({} as { [key: string]: true })
    const [showLinkCopiedModal, setShowLinkCopiedModal] = useState(false)
    const [readiumState, setReadiumState] = useState(false)
    const {t} = useTranslation()
    const ref = useRef(null)
    /**
     * Refs to the iframes that hold the `print.html` pages of the reader.
     * 
     * Those are the frames that need to be accessed when one wants to print a page
     */
    const printFrameRefs = [
        useRef<HTMLIFrameElement>(null),
        useRef<HTMLIFrameElement>(null),
    ]
    const sendPostMessage = (type: string, page: string) => {
        window.readerFrame.postMessage({type: type, page: page, edition: currentEdition}, "*")
    }

    useEffect(() => {
        function receiveMessage (event) {
            switch(event.data.type){
                case "piosk":
                    setActiveReaderButtons(event.data.buttons)
                    if ("sharing" in event.data) {
                        const sharingPlatforms = 
                            (event.data.sharing.platforms ?? [])
                                .reduce((acc, key) => {
                                    return {...acc, [key]: true}
                                }, {})
                        setSharingPlatforms(sharingPlatforms)
                        setIndividualSharing(true)
                    }
                    break
                case "pageNumbers":
                    const numbers = [event.data.numbers.left]
                    if(event.data.numbers.right){
                        numbers.push(event.data.numbers.right)
                    }
                    setPageNumbers(numbers)
                    setPageIds(event.data.pageIds)
                    break
                case 'readium':
                    setReadiumState(event.data.active)
                    break
            }
        }

        window.addEventListener("message", receiveMessage)
        return () => {
            window.removeEventListener("message", receiveMessage)
        }
    }, [])

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref && ref.current && !ref.current.contains(event.target)) {
                    // @ts-ignore
                    setDropdownActive(false)
                    setModalType('')
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const isMobile = /(android|iphone|ipad|mobile)/i.test(navigator.userAgent)

    const handleDropdown = () => {
        setDropdownActive(!dropdownActive)
        setModalType('')
    }

    const shareURL = (() => {
        if(currentEditionShareURL){
            if(chosenPage) {
                return currentEditionShareURL + `&publicationPage=${chosenPage}`
            }
            return currentEditionShareURL
        } else {
        const url = new URL(window.location.href)
        url.pathname = '/reader/' + editionId
        if (chosenPage) {
            url.search = `lbpage=${chosenPage}`
        }
        return url.toString()
        }


    })();

    const copyShareURL = () => {
        navigator.clipboard.writeText(shareURL)
        setShowLinkCopiedModal(true)
        setTimeout(() => setShowLinkCopiedModal(false), 2500)
    }

    return (
        <>
         {
             
         
            <Wrapper type={currentEditionType}>
                <Content>
                    <StyledLink to="/">
                        <Logo src={themeContext.logoImage}/>
                    </StyledLink>
                    <ButtonWrapper>
                                {
                                    currentEditionLesemodus &&
                                    <HeaderButton icon={IconPDF} title={readiumState ? t('pdf_view') : t('reading_mode')} onClick={() => sendPostMessage('lesemodus', null)}/>
                                }
                                
                                {
                                    activeReaderButtons.cart && 
                                    <HeaderButton icon={IconCart} title={t('cart')} onClick={() => sendPostMessage('cart', null)}/>
                                }

                                {
                                    (activeReaderButtons.download === "true" || activeReaderButtons.print === "true" || activeReaderButtons.social === "true") &&
                                <OutsideClickHelper ref={ref}>
                                    <HeaderButton tertiary icon={IconShare} onClick={() => handleDropdown()}>
                                        <Dropdown active={dropdownActive || modalType !== ''} >
                                            {
                                                modalType === '' &&
                                                <>
                                                    {
                                                        activeReaderButtons.download === "true" &&
                                                        <DropdownButton label={t('download')} icon={IconDownload} onClick={() => setModalType('download')} iconRight={IconChevronRight}/>
                                                    }
                                                    {
                                                        activeReaderButtons.print === "true" && 
                                                        <DropdownButton label={t('print')} icon={IconPrint} onClick={() => setModalType('print')} iconRight={IconChevronRight}/>
                                                    }


                                                    {
                                                        activeReaderButtons.social === "true" && 
                                                        <DropdownButton label={t('share')} icon={IconShare} onClick={() => setModalType('share')} iconRight={IconChevronRight}/>
                                                    }
                                                </>
                                                
                                            }
                                            {
                                                modalType === 'download' &&
                                                <>
                                                    <DropdownTitle>
                                                            {t('download')}
                                                            <Close src={IconClose}  onClick={() => setModalType('')}/>
                                                    </DropdownTitle>
                                                        {pageNumbers.map((number, index) => (
                                                            <DropdownItem id={`gtm_downloads_seite_${number}`} key={number} thumbnail={`${currentEdition}/preview/thumb/${pageIds[index] + 1}.jpg`} title={`${t('page')} ${number}`} icon={IconDownload} onClick={() => sendPostMessage('download', number)}/>
                                                    ))}
                                                    <DropdownItem id={`gtm_downloads_ganze_ausgabe`} thumbnail={`${currentEdition}/preview/thumb/1.jpg`} title={t('whole_edition')} icon={IconDownload} onClick={() => sendPostMessage('download', 'all')}/>
                                                </>
                                            }
                                            
                                            {
                                                modalType === 'print' && 
                                                    <>
                                                        <DropdownTitle>
                                                            {t('print')}
                                                            <Close src={IconClose}  onClick={() => setModalType('')}/>
                                                        </DropdownTitle>
                                                        
                                                        {pageNumbers.map((number, index) => ( /* TODO: don't use index to find print-frame */
                                                            <DropdownItem id={`gtm_print_seite_${number}`} key={number} thumbnail={`${currentEdition}/preview/thumb/${pageIds[index] + 1}.jpg`} title={`${t('page')} ${number}`} icon={IconPrint} onClick={() => printFrameRefs[index].current?.contentWindow?.postMessage({type: "printPage", page: "1"}, "*")}>
                                                                <iframe ref={printFrameRefs[index]} width="0" height="0" src={`${currentEdition}/print.html?page=${number}`} title={`${t('page')} ${number}`}/>
                                                            </DropdownItem>
                                                    ))}
                                                </>
                                            }
                                            {
                                                modalType === 'share' && 
                                                    <>
                                                        <DropdownTitle>
                                                            {t('share')}
                                                            <Close src={IconClose} onClick={() => setModalType('')}/>
                                                        </DropdownTitle>
                                                        
                                                        {pageNumbers.map((number, index) => (
                                                            <DropdownItem id={`gtm_share_seite_${number}`} key={number} thumbnail={`${currentEdition}/preview/thumb/${pageIds[index] + 1}.jpg`} title={`${t('page')} ${number}`} icon={IconCheckEmpty} active={chosenPage === number} activeIcon={IconCheck} onClick={() => setChosenPage(number)}/>
                                                        ))}
                                                        <DropdownItem id={`gtm_share_ganze_ausgabe`} thumbnail={`${currentEdition}/preview/thumb/1.jpg`} title={t('whole_edition')} icon={IconCheckEmpty} active={chosenPage === ''} activeIcon={IconCheck} onClick={() => setChosenPage('')}/>
                                                        <MediaTitle>{t('share_via')}</MediaTitle>
                                                        <MediaIcons>
                                                            { (!individualSharing || sharingPlatforms.FACEBOOK) &&
                                                                <FacebookShareButton url={shareURL}>
                                                                    <ShareIcon src={IconFacebook}/>
                                                                </FacebookShareButton>
                                                            }
                                                            { isMobile && (!individualSharing || sharingPlatforms.FACEBOOK_MESSENGER) &&
                                                                <ShareButton<any>
                                                                    url={shareURL}
                                                                    opts={null}
                                                                    networkName='Facebook Messenger'
                                                                    networkLink={(url, _options) => `fb-messenger://share?link=${encodeURIComponent(url)}`}
                                                                >
                                                                    <ShareIcon src={IconFacebookMessenger}/>
                                                                </ShareButton>
                                                            }
                                                            { (!individualSharing || sharingPlatforms.TWITTER) &&
                                                                <TwitterShareButton url={shareURL}>
                                                                    <ShareIcon src={IconX}/>
                                                                </TwitterShareButton>
                                                            }
                                                            { (!individualSharing || sharingPlatforms.LINKEDIN) &&
                                                                <LinkedinShareButton url={shareURL}>
                                                                    <ShareIcon src={IconLinkedin}/>
                                                                </LinkedinShareButton>
                                                            }
                                                            { (!individualSharing || sharingPlatforms.XING) &&
                                                                <ShareButton<any>
                                                                    url={shareURL}
                                                                    opts={null}
                                                                    networkName='Xing'
                                                                    networkLink={(url, _options) => `https://www.xing.com/spi/shares/new?url=${encodeURIComponent(url)}`}
                                                                >
                                                                    <ShareIcon src={IconXing}/>
                                                                </ShareButton>
                                                            }
                                                            { (!individualSharing || sharingPlatforms.MAIL) &&
                                                                <EmailShareButton url={shareURL} subject={t('share_email_subject', {edition: currentEditionTitle})} body={t('share_email_body', { edition: currentEditionTitle})}>
                                                                    <ShareIcon src={IconEmail}/>
                                                                </EmailShareButton>
                                                            }
                                                            { (!individualSharing || sharingPlatforms.PINTEREST) &&
                                                                <PinterestShareButton url={shareURL} media={currentEdition + "/cover.jpg"}>
                                                                    <ShareIcon src={IconPinterest}/>
                                                                </PinterestShareButton>
                                                            }
                                                            { (!individualSharing || sharingPlatforms.WHATSAPP) &&
                                                                <WhatsappShareButton url={t('share_email_body', { edition: currentEditionTitle}) + shareURL}>
                                                                    <ShareIcon src={IconWhatsApp}/>
                                                                </WhatsappShareButton>
                                                            }
                                                            { (!individualSharing || sharingPlatforms.HREF) &&
                                                                <ShareButton<any>
                                                                    url={shareURL}
                                                                    opts={null}
                                                                    networkName='Copy link'
                                                                    networkLink={(url, _options) => url}
                                                                    openShareDialogOnClick={false}
                                                                    onClick={copyShareURL}
                                                                >
                                                                    <ShareIcon src={IconLink}/>
                                                                </ShareButton>
                                                            }
                                                        </MediaIcons>
                                                </>
                                            }
                                            
                                        </Dropdown>
                                    </HeaderButton>
                                    </OutsideClickHelper>
                                }
                        
                        
                    </ButtonWrapper>
                </Content>
                <LinkCopiedModal className={showLinkCopiedModal ? '' : 'hidden'}>
                    <Trans i18nKey='link_copied_to_clipboard'>
                        ✓ Link in die Zwischenablage kopiert
                    </Trans>
                </LinkCopiedModal>
            </Wrapper>
            }
        </>
    )
}
const mapStateToProps = (state, props) => {
    return {
      currentEdition: state.editions.current.url,
      currentEditionTitle: state.editions.current.title,
      currentEditionType: state.editions.current.type,
      currentEditionShareURL: state.editions.current.shareURL,
      currentEditionLesemodus: state.editions.current.hasLesemodus
    }
}

export default connect(mapStateToProps)(ReaderHeader)

type WrapperProps = {
    type: string
}

const Wrapper = styled.div<WrapperProps>`
    height: 76px;  
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    position: ${props => props.type === 'COURSE' ? 'fixed' : 'static'};
    border-bottom: 1px solid ${props => props.theme.color.grey_200};
    @media ${device.mobile}{
        height: 56px;
    }
`

const Content = styled.span`
    width: 960px;
    @media ${device.tablet}{
        width: 820px;
    }
    display: flex;
    justify-content: space-between;
`

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    @media ${device.tablet}{
        margin-right: 12px;
    }
    @media ${device.mobile}{
        margin-right: 12px;
    }
`

const StyledLink = styled(Link)`
`

const Logo = styled.img`
    height: 36px;
    @media ${device.tablet}{
        margin-left: 12px;
    }
    @media ${device.mobile}{
        margin-left: 12px;
        height: 32px;
    }
`

const DropdownTitle = styled.span`
    display: flex;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 100%;
    height: 46px;
    border-bottom: 1px solid ${props => props.theme.color.grey_100};
    color: ${props => props.theme.color.grey_900};
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 14px;
    margin: 0 -4px;
    margin-bottom: 8px;
`

const Close = styled(SVG)`
    height: 14px;
    width: 14px;
    padding: 4px;
    border-radius: 6px;
    cursor: pointer;
    & path {
        fill: ${props => props.theme.color.grey_500};
    }
    &:hover{
        background-color: ${props => props.theme.color.grey_50};
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);

    }
`

const MediaTitle = styled.span`
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 100%;
    text-transform: uppercase;
    color: ${props => props.theme.color.grey_500};
    margin-left: 16px;
    margin-top: 20px;
`

const MediaIcons = styled.div`
    display: grid;
    grid-template: "1fr 1fr 1fr 1fr 1fr";
    grid-gap: 10px;
    margin: 10px;
`

const ShareIcon = styled(SVG)`
    width: 28px;
    height: 28px;

    &:hover .bg {
        fill: #677389;
    }
`

const OutsideClickHelper = styled.span``

const LinkCopiedModal = styled.div`
    position: absolute;
    top: 84px;
    height: 54px;
    width: 280px;
    border-radius: 4px;
    color: white;
    background-color: #222A3D;
    box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: 600;

    display: flex;
    justify-content: center;
    align-items: center;

    pointer-events: none;
    transition: opacity .5s;

    &.hidden {
        /* display: none; */
        opacity: 0;
    }
`